import logo from './logo.svg';
import './App.css';
import React from 'react';

function App() {
  return (
    <div className="App">
      <h1>Welcome to TeeTotalTab.com</h1>
      <h3>No content has been developed yet, but the website is running!</h3>
    </div>
  );
}

export default App;
